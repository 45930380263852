<template>
  <form @submit.prevent="doSubmit()">
    <div class="tw-p-6 md:tw-p-10 tw-drop-shadow-md tw-border-b-2">
      <div class="tw-flex tw-items-center tw-justify-between tw-mb-6">
        <router-link
          :to="{
            name: !this.id ? 'salon-services' : 'detail-salon-service',
            params: this.id ? { id: this.id } : null,
          }"
          class="text-lg tw-font-semibold tw-text-blue-700 tw-hover:tx-text-blue-900"
        >
          <i class="mr-2 pi pi-arrow-left"></i>
          Kembali
        </router-link>
        <div>
          <Button
            label="Batal"
            iconPos="left"
            class="tw-h-9 tw-text-sm tw-mr-3 tw-bg-transparent tw-text-black tw-hover:tw-bg-neutral-300 tw-border-gray-300"
            @click="
              $router.push({
                name: !this.id ? 'salon-services' : 'detail-salon-service',
                params: this.id ? { id: this.id } : null,
              })
            "
          />
          <Button
            type="submit"
            label="Simpan"
            icon="pi pi-check"
            class="tw-h-9 tw-text-sm tw-mb-3 sm:tw-mb-0 tw-bg-transparent tw-text-white tw-hover:tw-bg-neutral-300 tw-border-blue-light tw-bg-blue-light"
          />
        </div>
      </div>
      <h5 class="tw-text-xl inter-semibold tw-text-black">
        {{ !this.id ? "Tambah" : "Edit" }} Layanan
      </h5>
    </div>

    <div class="grid tw-justify-center tw-pt-16">
      <div class="col-12 sm:col-10 lg:col-6">
        <div class="input-group tw-mb-6" v-if="isAdminPriti() && !this.id">
          <label for="" class="tw-text-black inter-medium tw-mb-2 block">
            Salon <span class="tw-text-red-700">*</span>
          </label>
          <AutoComplete
            v-model="form.salon"
            :suggestions="salons_filter"
            :virtualScrollerOptions="{ itemSize: 38 }"
            optionLabel="name"
            optionValue="id"
            class="w-full"
            dropdown
            @complete="searchSalon"
          />
        </div>

        <div class="input-group tw-mb-6" v-if="!isAdminPriti() || this.id">
          <label for="" class="tw-text-black inter-medium tw-mb-2 block">
            Nama Salon
          </label>
          <InputText
            type="text"
            class="w-full"
            v-model="form.salon_name"
            :disabled="true"
          />
        </div>

        <div class="input-group tw-mb-6">
          <label for="" class="tw-text-black inter-medium tw-mb-2 block">
            Nama Layanan <span class="tw-text-red-700">*</span>
          </label>
          <InputText type="text" class="w-full" v-model="form.service_name" />
        </div>

        <div class="input-group tw-mb-6">
          <label for="" class="tw-text-black inter-medium tw-mb-2 block">
            Deskripsi <span class="tw-text-red-700">*</span>
          </label>
          <InputText type="text" class="w-full" v-model="form.service_desc" />
        </div>

        <div class="input-group tw-mb-6">
          <label for="" class="tw-text-black inter-medium tw-mb-2 block">
            Kategori <span class="tw-text-red-700">*</span>
          </label>
          <Dropdown
            v-model="form.category_id"
            :options="categories"
            optionLabel="name"
            optionValue="id"
            placeholder="Pilih Kategori"
            class="w-full"
          />
        </div>

        <div class="grid">
          <div class="col-12 md:col-6 pb-0">
            <div class="input-group tw-mb-6">
              <label for="" class="tw-text-black inter-medium tw-mb-2 block">
                Estimasi Waktu <span class="tw-text-red-700">*</span>
              </label>
              <InputNumber
                v-model="form.estimated_time"
                :useGrouping="false"
                class="w-full"
              />
            </div>
          </div>
          <div class="col-12 md:col-6 pb-0">
            <div class="input-group tw-mb-6">
              <label for="" class="tw-text-black inter-medium tw-mb-2 block">
                Satuan Durasi <span class="tw-text-red-700">*</span>
              </label>
              <Dropdown
                v-model="form.duration_unit"
                :options="durations"
                optionLabel="name"
                optionValue="value"
                placeholder="Pilih Satuan Durasi"
                class="w-full"
              />
            </div>
          </div>
        </div>

        <div class="input-group tw-mb-6">
          <label for="" class="tw-text-black inter-medium tw-mb-2 block">
            Harga <span class="tw-text-red-700">*</span>
          </label>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"> Rp </span>
            <InputNumber v-model="form.price" locale="id-ID" class="w-full" />
          </div>
        </div>

        <div class="input-group tw-mb-6">
          <label for="" class="tw-text-black inter-medium tw-mb-2 block">
            Foto Layanan <span class="tw-text-red-700">*</span>
          </label>
          <div class="input-file-wrapper">
            <div class="input-file" v-if="!form.image_preview">
              <Button
                type="button"
                iconPos="left"
                icon="pi pi-cloud-upload"
                class="tw-h-9 tw-text-sm tw-mb-3 tw-bg-transparent tw-text-black tw-hover:tw-bg-neutral-300 tw-border-gray-300"
              />
              <p>
                <span class="inter-bold tw-text-blue-light"
                  >Klik untuk unggah</span
                >
                atau seret ke sini
              </p>
              <p>JPG, JPEG, PNG (maks. 2MB)</p>
            </div>
            <div class="preview tw-p-6" v-else>
              <img :src="form.image_preview" alt="" />
              <div class="delete" @click="deleteFile()">
                <i class="pi pi-trash tw-text-white"></i>
              </div>
            </div>
            <input
              type="file"
              accept=".png, .jpeg, .jpg"
              @input="onUpload"
              v-if="!form.image_preview"
            />
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { useAuthRole } from "@/composables/auth";
import { useAuth } from "@websanova/vue-auth";

export default {
  name: "",
  props: ["id"],
  setup() {
    const { isAdminPriti } = useAuthRole();
    return { isAdminPriti };
  },

  data() {
    return {
      loading: false,
      form: {
        duration_unit: "minute",
        salon_name: "",
      },
      categories: [],
      salons: [],
      salons_filter: [],
      durations: [{ name: "Menit", value: "minute" }],
    };
  },

  created() {
    const auth = useAuth();
    this.form.salon_name = auth.user().salon_name;
  },

  mounted() {
    let promises = [];
    promises.push(this.getData());
    promises.push(this.getCategories());
    promises.push(this.getSalons());
    promises.push(this.getAllSalons());

    this.loading = true;
    Promise.all(promises)
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        console.error("nothing", error);
      });
  },

  methods: {
    getData() {
      if (!this.id) return;

      return new Promise((resolve, reject) => {
        this.$http
          .get(
            `${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/services/${this.id}`
          )
          .then((response) => {
            this.form = response.data.data;
            this.form.duration_unit = "minute";
            this.form.service_name = this.form.name;
            this.form.is_active = this.form.status == 1 ? true : false;
            this.form.image_preview = this.form.image;
            resolve(this.form);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getCategories() {
      return new Promise((resolve, reject) => {
        this.$http
          .get(`${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/categories/ddl`)
          .then((response) => {
            this.categories = response.data.data.categories;
            resolve(this.categories);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getSalons() {
      if (this.id) return;
      return new Promise((resolve, reject) => {
        this.$http
          .get(`${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/salons/dashboard/`)
          .then((response) => {
            this.salons = response.data.data.salons;
            resolve(this.salons);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getAllSalons() {
      return new Promise((resolve, reject) => {
        this.$http
          .get(`${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/salons/ddl`)
          .then((response) => {
            this.salons = response.data.data.salons;
            resolve(this.salons);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    searchSalon(event) {
      let query = event.query.toLowerCase();

      this.salons_filter = this.salons.filter((item) =>
        item.name.toLowerCase().includes(query)
      );
    },

    onUpload(event) {
      let files = event.target.files;
      if (!files.length) return;

      let file = files[0];

      if (file.type !== "image/png" && file.type !== "image/jpeg") {
        this.$toast.add({
          severity: "error",
          summary: "Error!",
          detail: "File tidak dapat di proses",
          life: 5000,
        });
        return;
      }

      let reader = new FileReader();
      this.form.image = file;

      reader.readAsDataURL(file);
      reader.onload = function () {
        let result = reader.result;
        this.form.image_preview = result;

        if (!this.id) return;

        let formData = new FormData();
        formData.append("image", this.form.image);

        this.$http
          .post(
            `${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/services/${this.id}/image`,
            formData
          )
          .then((response) => {
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: response.data.message,
              life: 3000,
            });
          })
          .catch((error) => {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: error.response.data.message,
              life: 3000,
            });
          });
      }.bind(this);
    },
    deleteFile() {
      this.form.image = null;
      this.form.image_preview = null;
    },

    doSubmit() {
      let method = this.id ? "put" : "post";
      let uri = this.id ? `api/v1/services/${this.id}` : `api/v1/services`;
      let formData = new FormData();

      formData.append("service_name", this.form.service_name);
      formData.append("service_desc", this.form.service_desc);
      formData.append("estimated_time", this.form.estimated_time);
      formData.append("category_id", this.form.category_id);
      formData.append(
        "salon_id",
        this.isAdminPriti()
          ? !this.id
            ? this.form.salon.id
            : this.form.salon_id
          : this.$auth.user().salon_id
      );
      formData.append("price", this.form.price);

      if (this.form.image) formData.append("image", this.form.image);

      this.$http[method](
        `${process.env.VUE_APP_PRITI_MAIN_API}/${uri}`,
        !this.id ? formData : this.form
      )
        .then((response) => {
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: response.data.message,
            life: 3000,
          });
          this.$router.push({
            name: !this.id ? "salon-services" : "detail-salon-service",
            params: this.id ? { id: this.id } : null,
          });
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: error.response.data.message,
            life: 3000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
